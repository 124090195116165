<div class="menuButton">
    <button type="button" pButton icon="pi pi-check" class="p-button-raised p-button-rounded" (click)="submit()"></button>
</div>


<div class="centeringDiv">
    <h1>Tag {{cycleDay?.cycleDayId}} | {{getDateString(cycleDay?.cycleDate)}}</h1>
    <div>
        <div class="inputDiv"><div class="textdiv">Zeit: </div><p-calendar timeOnly="true" [(ngModel)]="cycleDay.measuringTime"></p-calendar></div>
        <div class="inputDiv"><div class="textdiv">Ausklammer:</div> <p-checkbox [(ngModel)]="cycleDay.excludeDay" [binary]="true"></p-checkbox></div>
        <div class="inputDiv"><div class="textdiv">Temperatur: </div><p-inputNumber [(ngModel)]="cycleDay.temperature" mode="decimal" minFractionDigits=2 maxFractionDigits=2> </p-inputNumber></div>
        <div class="inputDiv"><div class="textdiv">Blut: </div><p-selectButton  [(ngModel)]="cycleDay.bloodAmount"  [options]="[{name : 'Keins', optionValue:'none'},{name : 'Wenig', optionValue:'less'},{name : 'Mittel', optionValue:'medium'},{name : 'Viel', optionValue:'high'}]" optionLabel="name"></p-selectButton></div>
        <div class="inputDiv"><div class="textdiv">Schleim: </div><p-selectButton [(ngModel)]="cycleDay.slime" [options]="[{name : '∅', optionValue:'none'},{name : 't', optionValue:'less'},{name : 'f', optionValue:'medium'},{name : 'S', optionValue:'high'},{name : 'S+', optionValue:'peak'}]" optionLabel="name"></p-selectButton></div>
        <div class="inputDiv"><div class="textdiv">Sex: </div><p-checkbox [(ngModel)]="cycleDay.sex" [binary]="true"></p-checkbox></div>
        <div class="inputDiv"><div class="textdiv">Kommentar: </div><textarea pInputTextarea [(ngModel)]="cycleDay.comment"></textarea></div>
    </div>
</div>

