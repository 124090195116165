<div class="centeringDiv">  
    <h1>Zyklus Übersicht</h1>
    <div *ngFor="let cycle of cycles" class="cycleButton">
        <button pButton (click)="goToCycle(cycle.cycleId)" class="p-button-raised p-button-rounded" [ngClass]="{'deleteButton': deleteMode}">Zyklus Nr. {{cycle.cycleId}}</button>
    </div>
    <div>
        <button pButton *ngIf="!adderExpanded" (click)="expandAdder()" class="p-button-raised p-button-rounded editButtons" label="➕"></button>
        <button pButton (click)="toggleDeleteMode()" class="p-button-raised p-button-rounded editButtons" [label]="deleteLabel"> </button>
    </div>
    <div *ngIf="adderExpanded" class="centeringDiv">
        Start Datum Auswählen
        <p-calendar [(ngModel)]="date" [showIcon]="true" class="calendar" dateFormat="dd.mm.yy"></p-calendar>
        <button pButton (click)="addCycle()" class="p-button-raised p-button-rounded">Hinzufügen</button>
    </div>
</div>