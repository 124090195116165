import { Component, NgModule, OnInit } from '@angular/core';
import { CycleDay } from '../data-types';
import { ActivatedRoute, Router } from '@angular/router';
import { DbserviceService } from '../dbservice.service';


@Component({
  selector: 'app-data-collection',
  templateUrl: './data-collection.component.html',
  styleUrls: ['./data-collection.component.css']
})
export class DataCollectionComponent implements OnInit {

  cycleDay: CycleDay;
  cycleId: number;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private dbServive: DbserviceService) { }

  ngOnInit(): void {
    this.dbServive.POSTLogin(localStorage.getItem('password')).subscribe((success: boolean) => {
      if(success){

        this.cycleDay = {cycleDayId : 0}

        this.route.queryParams.subscribe((params) => {
          this.cycleId = params['cycle'];
          this.cycleDay.cycleDayId = params['dateId'];
          this.dbServive.GETCycleDay(this.cycleId, this.cycleDay.cycleDayId).subscribe((cycleDay: CycleDay) => {
            console.log(cycleDay);
            this.cycleDay = cycleDay;
            if(this.cycleDay.measuringTime){
              this.cycleDay.measuringTime = new Date(this.cycleDay.measuringTime);
            }else{
              this.cycleDay.measuringTime = new Date();
            }
          })
        })

      }else{
        this.router.navigate(['/login']);
      }
    }, error => this.router.navigate(['/login']))
  }

  public getDateString(date: Date) : string{
    return new Date(date).toLocaleDateString();
  }

  setBlood(event){
    console.log(event.option.optionValue)
  }

  submit(){
    this.dbServive.POSTCycleDay(this.cycleId, this.cycleDay).subscribe((success: boolean) => {
      if(success){
        this.router.navigate(['/dashboard'], { queryParams: {cycle: this.cycleId}});
      }
    })
  }
}
