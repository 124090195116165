import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Cycle, CycleDay } from './data-types';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DbserviceService {

  //private apiURL = 'http://localhost:8080';
  private apiURL = 'https://nfp-back.clemenswue.synology.me:443';

  

  constructor(private http: HttpClient) { }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  POSTLogin(password: String): Observable<boolean> {
    return this.http
      .post<boolean>(this.apiURL + '/login', JSON.stringify({'password': password}), this.httpOptions);
  }

  GETCycles(): Observable<Cycle[]> {
    return this.http
      .get<Cycle[]>(this.apiURL + '/cycles');
  }

  GETCycle(cycleId: number): Observable<Cycle> {
    return this.http
    .get<Cycle>(this.apiURL + '/cycle/' + cycleId);
  }

  POSTNewCycle(startDate: Date): Observable<Cycle[]> {
    startDate.toJSON = function(){ return new Date(this).toString(); }
    return this.http
    .post<Cycle[]>(this.apiURL + '/cycle', JSON.stringify({'startDate': startDate}), this.httpOptions);
  }

  DELETECycle(cycleId: number): Observable<boolean>{
    return this.http
    .delete<boolean>(this.apiURL + "/cycle/" + cycleId);
  }

  GETCycleDay(cycleId: number, cycleDayId: number): Observable<CycleDay>{
    return this.http
    .get<CycleDay>(this.apiURL + '/cycleday/' + cycleId + "/" + cycleDayId);
  }

  POSTCycleDay(cycleId: number, cycleDay: CycleDay): Observable<boolean>{
    return this.http
    .post<boolean>(this.apiURL + '/cycleday/' + cycleId, JSON.stringify({'cycleDay': cycleDay}), this.httpOptions);
  }
}
