import { Component, OnInit } from '@angular/core';
import { Cycle, CycleDay } from '../data-types';
import { ActivatedRoute, Router } from '@angular/router';
import { DbserviceService } from '../dbservice.service';
import { PrimeIcons } from "primeng/api";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  chartData: any;
  chartOptions: any;
  cycle: Cycle; 
  timespanToShow: {name: string} = {name: "-6T"};

  constructor(private router: Router,
              private route: ActivatedRoute,
              private dbServive: DbserviceService) { }

  ngOnInit(): void {
    this.dbServive.POSTLogin(localStorage.getItem('password')).subscribe((success: boolean) => {
      if(success){
          this.cycle = {
            cycleId: 0,
            cycleDays: null
          }
          
          this.route.queryParams.subscribe((params) => {
            this.cycle.cycleId = params['cycle'];
            this.dbServive.GETCycle(this.cycle.cycleId).subscribe((cycle: Cycle) => {
              console.log(cycle);
              this.cycle = cycle;

              let cycleDaysCopy = cycle.cycleDays.slice();
              for (let i = cycleDaysCopy.length - 1; i >= 0; i--) {
                if(cycleDaysCopy[i].cycleDayId != 0 && cycleDaysCopy[i-1].temperature == undefined){
                  cycleDaysCopy.pop();
                }else{
                  break;
                }
              }

              let splittedValues = this.splitTempValues(cycleDaysCopy);
              console.log(splittedValues)

              //https://www.chartjs.org/docs/latest/charts/line.html#line-styling

              this.chartData = {
                labels: cycleDaysCopy
                .map(x => {
                    return new Date(x.cycleDate).toLocaleDateString() + " (" + x.cycleDayId + ") ";
                }),
                datasets: [
                      {
                        label: 'Excluded',
                        data: splittedValues[1],
                        fill: false,
                        borderDash: [5,5],
                        borderColor: "#b3b3b3",
                        tension: 0.0
                      },
                      {
                        label: 'Lower',
                        data: splittedValues[2],
                        fill: false,
                        borderColor: '#0a97eb',
                        pointBorderColor: 'rgb(0, 84, 134, 0.5)',
                        pointBorderWidth: 5,
                        tension: 0.0
                      },
                      {
                        label: 'Higher',
                        data: splittedValues[3],
                        fill: false,
                        borderColor: '#0a97eb',
                        pointBorderColor: 'rgb(250, 19, 40, 0.5)',
                        pointBorderWidth: 8,
                        tension: 0.0,
                        pointStyle: 'rect'
                      },
                      {
                          label: 'NormalDays',
                          data: splittedValues[0],
                          fill: false,
                          borderColor: '#0a97eb',
                          tension: 0.0
                      },
                      {
                        label: 'SlimePeak',
                        data: splittedValues[5],
                        fill: false,
                        pointBorderColor: 'rgb(255, 225, 30, 0.8)',
                        pointBorderWidth: 30,
                        pointStyle: 'star',
                        tension: 0.0
                      },
                      {
                          label: 'SafeDays',
                          data: splittedValues[4],
                          fill: true,
                          backgroundColor: 'rgb(50, 207, 90, 0.5)',
                          tension: 0.0
                      }
                  ]
                };
              })
            })
            this.chartOptions = {
              legend: {display: false},
              maintainAspectRatio: false,
              aspectRatio: 0.6,
          };
        }else{
          this.router.navigate(['/login']);
        }
      }, error => this.router.navigate(['/login']))
  }

  splitTempValues(cycledays: CycleDay[]){
    let excludedDays = new Array(cycledays.length);
    let normalDays = new Array(cycledays.length);
    let sixLowerDays = new Array(cycledays.length);
    let higherDays = new Array(cycledays.length);
    let safeDays = new Array(cycledays.length);
    let slimePeak = new Array(cycledays.length);
    let slimePeakId = 200;

    let cDnoExclude = cycledays.filter(cycleday => !cycleday.excludeDay)

    let higherDaysIDs = [];
    let lowerDaysIDs = [];

    for (let i = 0; i < cDnoExclude.length; i++) {
      if(cDnoExclude[i].temperature > cDnoExclude[i-1]?.temperature &&
        cDnoExclude[i].temperature > cDnoExclude[i-2]?.temperature &&
        cDnoExclude[i].temperature > cDnoExclude[i-3]?.temperature &&
        cDnoExclude[i].temperature > cDnoExclude[i-4]?.temperature &&
        cDnoExclude[i].temperature > cDnoExclude[i-5]?.temperature &&
        cDnoExclude[i].temperature > cDnoExclude[i-6]?.temperature )
         {
          let lastSixTemps = cDnoExclude.slice(i-6, i).map(day => day.temperature);
          let highestTempOfLastSix = Math.max(...lastSixTemps)
          if(cDnoExclude[i+1].temperature > highestTempOfLastSix &&
            cDnoExclude[i+2].temperature > highestTempOfLastSix)
            {
              if(cDnoExclude[i+2].temperature >= Number((highestTempOfLastSix + 0.2).toFixed(2))){
                higherDaysIDs.push(cDnoExclude[i].cycleDayId);
                higherDaysIDs.push(cDnoExclude[i+1].cycleDayId);
                higherDaysIDs.push(cDnoExclude[i+2].cycleDayId);
                
                lowerDaysIDs.push(cDnoExclude[i-1].cycleDayId);
                lowerDaysIDs.push(cDnoExclude[i-2].cycleDayId);
                lowerDaysIDs.push(cDnoExclude[i-3].cycleDayId);
                lowerDaysIDs.push(cDnoExclude[i-4].cycleDayId);
                lowerDaysIDs.push(cDnoExclude[i-5].cycleDayId);
                lowerDaysIDs.push(cDnoExclude[i-6].cycleDayId);
                break;
              }else if(cDnoExclude[i+3].temperature > highestTempOfLastSix){
                higherDaysIDs.push(cDnoExclude[i].cycleDayId);
                higherDaysIDs.push(cDnoExclude[i+1].cycleDayId);
                higherDaysIDs.push(cDnoExclude[i+2].cycleDayId);
                higherDaysIDs.push(cDnoExclude[i+3].cycleDayId);
                
                lowerDaysIDs.push(cDnoExclude[i-1].cycleDayId);
                lowerDaysIDs.push(cDnoExclude[i-2].cycleDayId);
                lowerDaysIDs.push(cDnoExclude[i-3].cycleDayId);
                lowerDaysIDs.push(cDnoExclude[i-4].cycleDayId);
                lowerDaysIDs.push(cDnoExclude[i-5].cycleDayId);
                lowerDaysIDs.push(cDnoExclude[i-6].cycleDayId);
                break;
              }
            }
        }
    } 

    for (let i = 0; i < cycledays.length; i++) {
      if(cycledays[i].slime != undefined){
        if(cycledays[i].slime.optionValue == "peak"){
          slimePeak[i] = cycledays[i].temperature;
          slimePeakId = cycledays[i].cycleDayId;
        }
      }
      if(i < 5){
        safeDays[i] = cycledays[i].temperature;
      }
      if(cycledays[i].cycleDayId > higherDaysIDs[higherDaysIDs.length -1] && cycledays[i].cycleDayId > slimePeakId + 2){
        safeDays[i] = cycledays[i].temperature;
      }
      if(cycledays[i].excludeDay){
        excludedDays[i-1] = cycledays[i-1]?.temperature;
        excludedDays[i] = cycledays[i].temperature;
        excludedDays[i+1] = cycledays[i+1]?.temperature;
      }else if(lowerDaysIDs.includes(cycledays[i].cycleDayId)){
        sixLowerDays[i] = cycledays[i].temperature;
        normalDays[i] = cycledays[i].temperature;
      }else if(higherDaysIDs.includes(cycledays[i].cycleDayId)){
        higherDays[i] = cycledays[i].temperature;
        normalDays[i] = cycledays[i].temperature;
      }else{
        normalDays[i] = cycledays[i].temperature;
      }
    }

    return [normalDays, excludedDays, sixLowerDays, higherDays, safeDays, slimePeak];
  }

  toOverview(){
    
    this.router.navigate(['/overview']);
  }

  toCycleDate(dateId: number){
    this.router.navigate(['/data-collection'], { queryParams: { cycle: this.cycle.cycleId, dateId: dateId}});
  }

  toCycleDateChart(event){
    this.router.navigate(['/data-collection'], { queryParams: { cycle: this.cycle.cycleId, dateId: event.element._index + 1}});
  }

  formatDate(cycleDate: Date){
    let formatted = "";
    if(new Date(cycleDate).getDate() < 10){
      formatted = formatted + 0
    }
    formatted = formatted + new Date(cycleDate).getDate() + ".";
    if(new Date(cycleDate).getMonth() < 10){
      formatted = formatted + 0
    }
    formatted = formatted + (new Date(cycleDate).getMonth() + 1 ) + ".";
    return formatted;
  }

  formatTime(measuringTime: Date){
    if(measuringTime == undefined){
      return "--:--";
    }
    let formatted = new Date(measuringTime).getHours() + ":" + (new Date(measuringTime).getMinutes());
    if(new Date(measuringTime).getMinutes() < 10){
      formatted = formatted + 0
    }
    return formatted;
  }

}
