import { Component, OnInit } from '@angular/core';
import { Cycle } from '../data-types';
import { DbserviceService } from '../dbservice.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {

  cycles: Cycle[];
  adderExpanded: boolean = false;
  deleteMode: boolean = false;
  deleteLabel: string = "🗑️";
  date: Date;
  

  constructor(private router: Router,
              private route: ActivatedRoute,
              private dbServive: DbserviceService) { }

  ngOnInit(): void {
    this.dbServive.POSTLogin(localStorage.getItem('password')).subscribe((success: boolean) => {
      if(success){

        this.dbServive.GETCycles().subscribe((cycles: Cycle[]) => {
          console.log(cycles);
          this.cycles = cycles;
        });

      }else{
        this.router.navigate(['/login']);
      }
    }, error => this.router.navigate(['/login']))
  }

  goToCycle(cycleId: number){
    if(this.deleteMode){
      let confimation = window.confirm("Zyklus Nr." + cycleId + " wirklich löschen?")
      if(confimation){
        this.dbServive.DELETECycle(cycleId).subscribe((success: boolean) => {
          if(success){
            window.location.reload();
          }
        });
      }
    }else{
      this.router.navigate(['/dashboard'], { queryParams: { cycle: cycleId}});
    }
  }

  expandAdder(){
    this.adderExpanded = true;
    this.deleteMode = false;
  }

  addCycle(){
    this.adderExpanded = false;
    this.date = new Date(this.date.setHours(this.date.getHours() - 15));
    this.dbServive.POSTNewCycle(this.date).subscribe((cycles: Cycle[]) => {
      console.log(cycles);
      this.cycles = cycles;
    });
  }

  toggleDeleteMode(){
    this.adderExpanded = false;
    this.deleteMode = !this.deleteMode;
    if(this.deleteMode){
      this.deleteLabel = "✖️";
    }else{
      this.deleteLabel = "🗑️";
    }
  }

}
