<div class="menuButton">
    <button type="button" pButton icon="pi pi-list" class="p-button-raised p-button-rounded" (click)="toOverview()"></button>
</div>
<div class="centeringDiv">
    <div>
        <h1>Zyklus Nr. {{cycle?.cycleId}}</h1>
    </div>

    <!--<p-selectButton [(ngModel)]="timespanToShow"  [options]="[{name : '-6T'},{name : '-12T'},{name : 'all'}]" optionLabel="name"></p-selectButton>-->

    <div class="chartbox">
        <p-chart type="line" [data]="chartData" [options]="chartOptions" (onDataSelect)="toCycleDateChart($event)" class="chart"></p-chart>
    </div>

    
    <button type="button" pButton class="cycleDayButton" *ngFor="let cycleDay of cycle?.cycleDays" (click)="toCycleDate(cycleDay?.cycleDayId)">
        <div class="cycle-day-id-div">
            {{cycleDay?.cycleDayId}}
        </div>
        <div class="splitDiv">
            <div class="centeringDiv">  
                <div>
                    {{formatDate(cycleDay?.cycleDate)}}  {{formatTime(cycleDay?.measuringTime)}}
                </div>
                <div class="emojiDiv">
                    <div class="tempdiv">{{cycleDay?.temperature}} °C</div>
                    <div [ngSwitch]="cycleDay?.bloodAmount?.optionValue" class="blooddiv">
                        <div *ngSwitchCase="'high'">🩸🩸🩸</div>
                        <div *ngSwitchCase="'medium'">🩸🩸</div>
                        <div *ngSwitchCase="'less'">🩸</div>
                    </div>
                    <div [ngSwitch]="cycleDay?.slime?.optionValue" class="blooddiv">
                        <div *ngSwitchCase="'none'">∅</div>
                        <div *ngSwitchCase="'less'">t</div>
                        <div *ngSwitchCase="'medium'">f</div>
                        <div *ngSwitchCase="'high'">S</div>
                        <div *ngSwitchCase="'peak'">S+</div>
                    </div>
                    <div  class="subdiv">
                        <ng-container *ngIf="cycleDay?.sex">💏</ng-container>
                    </div>
                </div>
            </div>
        </div>
    </button>
</div>
