import { Component, OnInit } from '@angular/core';
import { DbserviceService } from '../dbservice.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  password: String;
  error: String = "";

  constructor(private router: Router, 
              private dbServive: DbserviceService) { }

  ngOnInit(): void {
    if(localStorage.getItem('password')){
      this.password = localStorage.getItem('password');
      this.login();
    }
  }

  login(){
    if(this.password != undefined){
      this.dbServive.POSTLogin(this.password).subscribe((success: boolean) => {
        if(success){
          localStorage.setItem("password", this.password.toString());
          this.router.navigate(['/overview']);

        }else{
          this.error = "Login fehlgeschlagen."
        }
      }, error => this.error = "Login fehlgeschlagen.")
    }
  }

}
